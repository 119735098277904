<template>
  <v-footer
    class="nav-footer"
  >
    <div class="text-center">
      <v-btn
        v-for="link in getLink"
        :key="link.id"
        variant="text"
        class="mx-2 text-none custom-button"
        rounded="xl"
        @click="redirectToAnotherComponent(link.id)"
      >
        <template #default>
          {{ link.link }}
        </template>
      </v-btn>
    </div>

    <div class="text-center">
      <v-btn
        v-for="icon in icons"
        :key="icon.id"
        :class="getButtonClass(icon.color)"
        :color="icon.color"
        :icon="icon.icon"
        variant="text"
        target="_blank"
        :href="icon.href"
      />
    </div>
    <div class="ml-auto text-contact-footer">
      <p>+359 886 77 91 11</p>
      <p
        class="download-link"
        @click="redirectToAnotherComponent('contacts')"
      >office@cocochoco-bulgaria.com</p>
    </div>
    <div
      class="cursor-pointer"
      @click="redirectToAnotherComponent('home')">
      <coco-logo
        :height="80"
        logoColor="black"
      />
    </div>
  </v-footer>
</template>

<script>
import CocoLogo from '@/components/logo/CocoLogo.vue';
import {headFooterMixins} from '@/components/mixins/headFooterMixins';

export default {
  name: 'NavFooter',
  components: {CocoLogo},
  mixins: [headFooterMixins],
  methods: {},
}
</script>

<style scoped>

.text-input {
  background: white;
}

.nav-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: black;
  color: white;
  /*position: absolute;*/
  left: 0;
  bottom: 0;
  width: 100%;
}

.text-center {
  text-align: center;
}

.download-link:hover {
  cursor: pointer;
  text-decoration: underline;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
