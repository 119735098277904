<template>

  <custom-app-bar/>

  <h1 class="text-center title-product">{{ $t('afterCare.afterCareTitle') }}</h1>
  <div class="container">
    <div class="text-left sub-title">
      <a class="sub-title-product" @click="scrollToDescription('01')">{{ $t('afterCare.shampooBalsamTitle') }}</a>
    </div>
    <div class="rows">
      <div class="product-container">
        <div v-for="(product,index) in productsShampooBalsam" :key="product.id" class="product-card">
          <v-card class="product-card-inner" @click="scrollToDescription(index + 'b')">
            <v-img :src="product.image" height="200px"></v-img>
            <v-card-title class="v-card-title">{{ product.name }}</v-card-title>
          </v-card>
        </div>
      </div>
    </div>

    <div class="text-left sub-title">
      <p class="sub-title-product" @click="scrollToDescription('11')">{{ $t('afterCare.hairMasksTitle') }}</p>
    </div>

    <div class="rows">
      <div class="product-container">
        <div v-for="(product,index) in productsHairMasks" :key="product.id" class="product-card">
          <v-card class="product-card-inner" @click="scrollToDescription(index + 'm')">
            <v-img :src="product.image" height="200px"></v-img>
            <v-card-title class="v-card-title">{{ product.name }}</v-card-title>
          </v-card>
        </div>
      </div>
    </div>

    <div class="text-left sub-title">
      <p class="sub-title-product" @click="scrollToDescription('22')">{{ $t('afterCare.serumTitle') }}</p>
    </div>

    <div class="rows">
      <div class="product-container">
        <div v-for="(product,index) in productsSerum" :key="product.id" class="product-card">
          <v-card class="product-card-inner" @click="scrollToDescription(index + 's')">
            <v-img :src="product.image" height="200px"></v-img>
            <v-card-title class="v-card-title">{{ product.name }}</v-card-title>
          </v-card>
        </div>
      </div>
    </div>


    <div class="descriptions ma-lg-16">
      <div class="text-center sub-title" id="description-01">
        <p>{{ $t('afterCare.shampooBalsamTitle') }}</p>
      </div>
      <div class="description about-text text-justify" id="description-0b">
        <div class="text-center pa-5">
          <h2>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo1.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo1.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo1.three') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo1.four') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo1.five') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo1.six') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo1.seven') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo1.eight') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo1.nine') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo1.ten') }}</p>
          </div>
        </div>
      </div>

      <div class="description about-text text-justify" id="description-1b">
        <div class="text-center pa-5">
          <h2>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo2.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo2.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo2.three') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo2.four') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo2.five') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo2.six') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo2.seven') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo2.eight') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo2.nine') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateShampoo2.ten') }}</p>
          </div>
        </div>
      </div>

      <div class="description about-text text-justify" id="description-2b">
        <div class="text-center pa-5">
          <h2>{{ $t('afterCare.shampooBalsam.freeSulphateBalsam.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('afterCare.shampooBalsam.freeSulphateBalsam.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateBalsam.three') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateBalsam.four') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateBalsam.five') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateBalsam.six') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateBalsam.seven') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateBalsam.eight') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateBalsam.nine') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.shampooBalsam.freeSulphateBalsam.ten') }}</p>
          </div>
        </div>
      </div>

      <div class="description about-text text-justify" id="description-3b">
        <div class="text-center pa-5">
          <h2>{{ $t('afterCare.shampooBalsam.ceramide1.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('afterCare.shampooBalsam.ceramide1.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('afterCare.shampooBalsam.ceramide1.three') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.ceramide1.four') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.ceramide1.five') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.ceramide1.six') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.shampooBalsam.ceramide1.seven') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.shampooBalsam.ceramide1.eight') }}</p>
          </div>
        </div>
      </div>

      <div class="description about-text text-justify" id="description-4b">
        <div class="text-center pa-5">
          <h2>{{ $t('afterCare.shampooBalsam.ceramide2.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('afterCare.shampooBalsam.ceramide2.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('afterCare.shampooBalsam.ceramide2.three') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.ceramide2.four') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.ceramide2.five') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.ceramide2.six') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.shampooBalsam.ceramide2.seven') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.shampooBalsam.ceramide2.eight') }}</p>
          </div>
        </div>
      </div>

      <div class="description about-text text-justify" id="description-5b">
        <div class="text-center pa-5">
          <h2>{{ $t('afterCare.shampooBalsam.ceramideVolumizing1.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('afterCare.shampooBalsam.ceramideVolumizing1.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('afterCare.shampooBalsam.ceramideVolumizing1.three') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.ceramideVolumizing1.four') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.ceramideVolumizing1.five') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.ceramideVolumizing1.six') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.shampooBalsam.ceramideVolumizing1.seven') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.shampooBalsam.ceramideVolumizing1.eight') }}</p>
          </div>
        </div>
      </div>

      <div class="description about-text text-justify" id="description-6b">
        <div class="text-center pa-5">
          <h2>{{ $t('afterCare.shampooBalsam.ceramideVolumizing2.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('afterCare.shampooBalsam.ceramideVolumizing2.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('afterCare.shampooBalsam.ceramideVolumizing2.three') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.ceramideVolumizing2.four') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.ceramideVolumizing2.five') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.ceramideVolumizing2.six') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.shampooBalsam.ceramideVolumizing2.seven') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.shampooBalsam.ceramideVolumizing2.eight') }}</p>
          </div>
        </div>
      </div>

      <div class="description about-text text-justify" id="description-7b">
        <div class="text-center pa-5">
          <h2>{{ $t('afterCare.shampooBalsam.silverTouch1.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('afterCare.shampooBalsam.silverTouch1.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('afterCare.shampooBalsam.silverTouch1.three') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.silverTouch1.four') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.silverTouch1.five') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.silverTouch1.six') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.shampooBalsam.silverTouch1.seven') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.shampooBalsam.silverTouch1.eight') }}</p>
          </div>
        </div>
      </div>

      <div class="description about-text text-justify" id="description-8b">
        <div class="text-center pa-5">
          <h2>{{ $t('afterCare.shampooBalsam.silverTouch2.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('afterCare.shampooBalsam.silverTouch2.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('afterCare.shampooBalsam.silverTouch2.three') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.silverTouch2.four') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.silverTouch2.five') }}</p>
            <p>{{ $t('afterCare.shampooBalsam.silverTouch2.six') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.shampooBalsam.silverTouch2.seven') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.shampooBalsam.silverTouch2.eight') }}</p>
          </div>
        </div>
      </div>

      <div class="text-center sub-title" id="description-11">
        <p>{{ $t('afterCare.hairMasksTitle') }}</p>
      </div>

      <div class="description about-text text-justify" id="description-0m">
        <div class="text-center pa-5">
          <h2>{{ $t('afterCare.hairMasks.keratinRepair.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('afterCare.hairMasks.keratinRepair.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('afterCare.hairMasks.keratinRepair.three') }}</p>
            <p>{{ $t('afterCare.hairMasks.keratinRepair.four') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.hairMasks.keratinRepair.five') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.hairMasks.keratinRepair.six') }}</p>
          </div>
        </div>
      </div>

      <div class="description about-text text-justify" id="description-1m">
        <div class="text-center pa-5">
          <h2>{{ $t('afterCare.hairMasks.boostUp.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('afterCare.hairMasks.boostUp.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('afterCare.hairMasks.boostUp.three') }}</p>
            <p>{{ $t('afterCare.hairMasks.boostUp.four') }}</p>
            <p>{{ $t('afterCare.hairMasks.boostUp.five') }}</p>
            <p>{{ $t('afterCare.hairMasks.boostUp.six') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.hairMasks.boostUp.seven') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.hairMasks.boostUp.eight') }}</p>
          </div>
        </div>
      </div>

      <div class="description about-text text-justify" id="description-2m">
        <div class="text-center pa-5">
          <h2>{{ $t('afterCare.hairMasks.cshmere.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('afterCare.hairMasks.cshmere.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('afterCare.hairMasks.cshmere.three') }}</p>
            <p>{{ $t('afterCare.hairMasks.cshmere.four') }}</p>
            <p>{{ $t('afterCare.hairMasks.cshmere.five') }}</p>
            <p>{{ $t('afterCare.hairMasks.cshmere.six') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.hairMasks.cshmere.seven') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.hairMasks.cshmere.eight') }}</p>
          </div>
        </div>
      </div>

      <div class="description about-text text-justify" id="description-3m">
        <div class="text-center pa-5">
          <h2>{{ $t('afterCare.hairMasks.silver.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('afterCare.hairMasks.silver.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('afterCare.hairMasks.silver.three') }}</p>
            <p>{{ $t('afterCare.hairMasks.silver.four') }}</p>
            <p>{{ $t('afterCare.hairMasks.silver.five') }}</p>
            <p>{{ $t('afterCare.hairMasks.silver.six') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.hairMasks.silver.seven') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.hairMasks.silver.eight') }}</p>
          </div>
        </div>
      </div>

      <div class="text-center sub-title" id="description-22">
        <p>{{ $t('afterCare.serumTitle') }}</p>
      </div>

      <div class="description about-text text-justify" id="description-0s">
        <div class="text-center pa-5">
          <h2>{{ $t('afterCare.serum.diamondDrops.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('afterCare.serum.diamondDrops.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('afterCare.serum.diamondDrops.three') }}</p>
            <p>{{ $t('afterCare.serum.diamondDrops.four') }}</p>
            <p>{{ $t('afterCare.serum.diamondDrops.five') }}</p>
            <p>{{ $t('afterCare.serum.diamondDrops.six') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.serum.diamondDrops.seven') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.serum.diamondDrops.eight') }}</p>
          </div>
        </div>
      </div>

      <div class="description about-text text-justify" id="description-1s">
        <div class="text-center pa-5">
          <h2>{{ $t('afterCare.serum.cherryBlossom1.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('afterCare.serum.cherryBlossom1.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('afterCare.serum.cherryBlossom1.three') }}</p>
            <p>{{ $t('afterCare.serum.cherryBlossom1.four') }}</p>
            <p>{{ $t('afterCare.serum.cherryBlossom1.five') }}</p>
            <p>{{ $t('afterCare.serum.cherryBlossom1.six') }}</p>
            <p>{{ $t('afterCare.serum.cherryBlossom1.seven') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.serum.cherryBlossom1.eight') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.serum.cherryBlossom1.nine') }}</p>
          </div>
        </div>
      </div>

      <div class="description about-text text-justify" id="description-2s">
        <div class="text-center pa-5">
          <h2>{{ $t('afterCare.serum.silkBoos.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('afterCare.serum.silkBoos.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('afterCare.serum.silkBoos.three') }}</p>
            <p>{{ $t('afterCare.serum.silkBoos.four') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.serum.silkBoos.five') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.serum.silkBoos.six') }}</p>
          </div>
        </div>
      </div>

      <div class="description about-text text-justify" id="description-3s">
        <div class="text-center pa-5">
          <h2>{{ $t('afterCare.serum.cherryBlossom2.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('afterCare.serum.cherryBlossom2.two') }}</p>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.serum.cherryBlossom2.three') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('afterCare.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('afterCare.serum.cherryBlossom2.four') }}</p>
          </div>
        </div>
      </div>

    </div>
  </div>
  <nav-footer/>
  <div class="scroll-to-top-btn" @click="scrollToTop">
    <v-icon>mdi-chevron-up</v-icon>
  </div>
</template>

<script>
import NavFooter from '@/components/footer/NavFooter.vue';
import CustomAppBar from '@/components/header/CustomAppBar.vue';

export default {
  name: 'AfterCare',
  components: {CustomAppBar, NavFooter},
  data() {
    return {
      productsShampooBalsam: [
        {
          id: 0,
          name: 'Free Sulphate Shampoo',
          image: require('@/assets/pic/afterCare/1.png'),
        },
        {
          id: 1,
          name: 'Free Sulphate Shampoo',
          image: require('@/assets/pic/afterCare/2.png'),
        },
        {
          id: 2,
          name: 'Free Sulphate Balsam',
          image: require('@/assets/pic/afterCare/3.png'),
        },
        {
          id: 3,
          name: 'Ceramide',
          image: require('@/assets/pic/afterCare/4.png'),
        },
        {
          id: 4,
          name: 'Ceramide',
          image: require('@/assets/pic/afterCare/5.png'),
        },
        {
          id: 5,
          name: 'Ceramide volumizing',
          image: require('@/assets/pic/afterCare/6.png'),
        },
        {
          id: 6,
          name: 'Ceramide volumizing',
          image: require('@/assets/pic/afterCare/7.png'),
        },
        {
          id: 7,
          name: 'Silver touch',
          image: require('@/assets/pic/afterCare/8.png'),
        },
        {
          id: 8,
          name: 'Silver touch',
          image: require('@/assets/pic/afterCare/9.png'),
        },
      ],
      productsHairMasks: [
        {
          id: 9,
          name: 'Keratin Repair',
          image: require('@/assets/pic/afterCare/10.png'),
        },
        {
          id: 10,
          name: 'Boost Up',
          image: require('@/assets/pic/afterCare/11.png'),
        },
        {
          id: 11,
          name: 'Cshmere Mask',
          image: require('@/assets/pic/afterCare/12.png'),
        },
        {
          id: 12,
          name: 'Silver Touch',
          image: require('@/assets/pic/afterCare/13.png'),
        },

      ],
      productsSerum: [
        {
          id: 13,
          name: 'Diamond Drops',
          image: require('@/assets/pic/afterCare/14.png'),
        },
        {
          id: 14,
          name: 'Cherry Blossom',
          image: require('@/assets/pic/afterCare/15.png'),
        },
        {
          id: 15,
          name: 'Silk Boost',
          image: require('@/assets/pic/afterCare/16.png'),
        },
        {
          id: 16,
          name: 'Cherry Blossom',
          image: require('@/assets/pic/afterCare/17.png'),
        },
      ]
    }
  },
  methods: {
    scrollToDescription(index) {
      // Изчакайте малко, за да осигурите изпълнението на Vue.js и DOM обновленията
      this.$nextTick(() => {
        const descriptionElement = document.getElementById(`description-${index}`);
        if (descriptionElement) {
          // Използвайте smooth scroll за плавно препращане към описанието
          descriptionElement.scrollIntoView({behavior: 'smooth'});
        }
      });
    },
    scrollToTop() {
      // Използвайте smooth scroll за плавно връщане най-отгоре на страницата
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
  },
}
</script>

<style scoped>
.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product-card {
  margin: 30px;
  width: 200px;
}

.product-card-inner {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 49px;
  font-weight: 10;
  font-family: 'Roboto';
}

.product-card-inner v-img {
  /* Задайте фиксирана височина на изображенията, за да се адаптират към височината на картичката */
  height: 200px;
  width: auto;
}

.v-card-title {
  /* Задайте ограничена ширина на тайтъла и скрийте излишния текст със свойството text-overflow */
  max-width: 200px; /* Тук задайте желаната дължина на тайтъла */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container {
  font-size: 18px;
}

.rows {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.row {
  cursor: pointer;
  margin-bottom: 10px;
  color: blue;
  text-decoration: underline;

}

.description h2::after {
  content: ''; /* Добавете съдържание на псевдоелемента */
  position: absolute; /* Задайте позиция absolute, за да го позиционирате под заглавието */
  bottom: -7px; /* Задайте разстояние под заглавието */
  left: 50%; /* Позицията на псевдоелемента от ляво до дясно */
  width: 100%; /* Ширина на псевдоелемента, която да покрива цялата ширина на заглавието */
  height: 7px; /* Височина на линията, може да се промени според вашия дизайн */
  background-color: darkgoldenrod; /* Цветът на линията */
  transform: translateX(-50%); /* Центриране на псевдоелемента спрямо собствения му център */
}

</style>
