<template>
  <div>
    <v-img
      :src="getLogoColor"
      cover
      :height="getHeight"
    />
  </div>
</template>

<script>

export default {
  name: 'CocoLogo',
  props: {
    height: {
      type: Number,
      default: 50
    },
    width: {
      type: Number,
      default: 50
    },
    logoColor: String,
  },
  data() {
    return {
      linkLogo: null,
    }
  },
  computed: {
    getHeight() {
      return this.height;
    },
    getWith() {
      return this.height;
    },
    getLogoColor() {
      if (this.logoColor === 'white') {
        return require('@/assets/cocochoco-logo.png');
      } else {
        return require('@/assets/cocochoco-black-logo.png');
      }
    }
  },
}
</script>

<style scoped>

</style>
