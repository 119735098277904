import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs.js'

import { gr } from './i18n/gr'
import { bg } from './i18n/bg'

const messages = {
  bg: bg,
  gr: gr
}

const i18n = createI18n({
  locale: 'bg',
  fallbackLocale: 'gr',
  messages
})

export default i18n
