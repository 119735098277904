<template>
  <v-app>
    <custom-app-bar/>
    <v-container :fluid="true" class="background-carousel">
    <v-carousel
      hide-delimiters
      :height="calculateCarouselHeight()"
      :cycle="true"
      :interval="5000"
    >
      <v-carousel-item
        v-for="srcImg in srcImgIndexMain"
        :key="srcImg.id"
        cover
        :src="srcImg.src"
      >
      </v-carousel-item>
    </v-carousel>
    </v-container>
    <nav-footer/>
  </v-app>
</template>

<script>

import NavFooter from '@/components/footer/NavFooter.vue';
// import NavHeader from '@/components/header/NavHeader.vue';
import CustomAppBar from '@/components/header/CustomAppBar.vue';

export default {
  name: 'Index',
  components: {CustomAppBar, NavFooter},
  data() {
    return {
      drawer: true,
      test: null,
      srcImgIndexMain: [
        {src: require('@/assets/pic/index-main/1.png'), id: 1},
        {src: require('@/assets/pic/index-main/3.png'), id: 2},
        {src: require('@/assets/pic/index-main/2.png'), id: 3},
        {src: require('@/assets/pic/index-main/4.png'), id: 4},
      ],
    }
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
    calculateCarouselHeight() {
      // Изчисляваме височината на carousel като изваждаме височината на custom-app-bar от височината на екрана
      return `${window.innerHeight - 134}px`; // Предполагаме, че custom-app-bar има височина 64px, променете го според вашия дизайн
    },
  }
}
</script>

<style scoped>
.background-carousel {
  //background-image: url('/src/assets/pic/contacts/botox splash1.jpg');
  background-size: cover;
  background-position: center;
  background-color: gold;
  /*filter: brightness(0.9);*/
}
</style>
