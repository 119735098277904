<template>

  <div class="custom-app-bar pb-6 pt-6">
    <v-sheet class="app-bar-background"></v-sheet>

    <div class="buttons-container">
      <div class="mr-3">
        <coco-logo :height="getSize" logoColor="black" @click="redirectToAnotherComponent('home')"/>
      </div>
      <v-btn
        v-for="link in getLink"
        :key="link.id"
        variant="text"
        color="white"
        class="text-none custom-button white-text-button  pt-2"
        rounded="xl"
        @click="redirectToAnotherComponent(link.id)"
      >
        <template #default>
          {{ link.link }}
        </template>
      </v-btn>

      <div class="buttons-container-mobile">
        <v-btn
          v-for="icon in icons"
          :key="icon.id"
          :class="getButtonClass(icon.color)"
          :color="icon.color"
          :icon="icon.icon"
          variant="text"
          target="_blank"
          :href="icon.href"
        />

        <v-btn variant="plain">
          <v-img
            max-width="25"
            max-height="25"
            contain
            :src="require('@/assets/flags/bg-flag.png')"
            @click="changeLanguage('bg')"
          />
        </v-btn>

        <v-btn variant="plain">
          <v-img
            max-width="25"
            max-height="25"
            contain
            :src="require('@/assets/flags/gr-flag.png')"
            @click="changeLanguage('gr')"
          />
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import CocoLogo from '@/components/logo/CocoLogo.vue';
import {headFooterMixins} from '@/components/mixins/headFooterMixins';

export default {
  name: 'CustomAppBar',
  components: {CocoLogo},
  mixins: [headFooterMixins],
  computed: {
    getSize() {
      if (window.innerWidth <= 768) {
        return 80;
      } else {
        return 50;
      }
    },
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    }
  }
}
</script>

<style scoped>

.custom-app-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 16px;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  background-color: black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Можете да промените сянката за по-добър ефект */
  z-index: 999; /* Задайте подходящо z-index, ако има други компоненти с фиксирана позиция */

}

.app-bar-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9); /* Променено на полупрозрачен черен цвят */
  z-index: -1;
}

.app-bar-image {
  max-height: 100px;
  /*margin: 16px;*/
}

.buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
}

.buttons-container-mobile {
  /*display: none;*/
  /*display: flex;*/
  /*justify-content: space-between;*/
  /*width: 100%;*/
}

/* Медиа заявка за мобилен изглед */
@media (max-width: 600px) {
  .custom-app-bar {
    flex-direction: column;
    z-index: 999;
  }
  .mobile-app-bar-image{
    //padding-top: 46px;
    //margin-top: 32px;
  }

  .app-bar-image {
    margin-top: 0;
    margin-bottom: 16px;
  }

  .buttons-container {
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
  }

  .buttons-container-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px;
  }
}

</style>
