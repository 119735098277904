<template>
  <custom-app-bar/>
  <v-form @submit.prevent="submitForm" class="contact-form">
    <v-container>
      <div class="contact-message">
        <h1>{{ $t('contact.connectUs') }}</h1>
        <div>
          <v-btn
            v-for="icon in icons"
            :key="icon.id"
            :class="getButtonClass(icon.color)"
            :color="icon.color"
            :icon="icon.icon"
            variant="text"
            target="_blank"
            :href="icon.href"
          />
        </div>
        <div class="text-contact-footer bold-label">
          <p>office@cocochoco-bulgaria.com</p>
          <p class="pt-5">+359 886 77 91 11</p>
        </div>

      </div>
      <v-row>
        <v-col cols="3">
        </v-col>
        <v-col cols="6">
          <v-row justify="center">
            <v-col cols="12" sm="6" md="10" class="form-inputs">
              <v-text-field
                v-model="name"
                :label="$t('contact.name')"
                required
                class="rounded-input"
              ></v-text-field>
              <v-text-field
                v-model="email"
                :label="$t('contact.email')"
                required
                type="email"
                class="rounded-input"
              ></v-text-field>
              <v-text-field
                v-model="phone"
                :label="$t('contact.phone')"
                required
                type="phone"
                class="rounded-input"
              ></v-text-field>
              <v-textarea
                v-model="message"
                :label="$t('contact.message')"
                required
                class="no-bottom-border rounded-input"
              ></v-textarea>
              <v-row justify="center">
                <v-col cols="auto">
                  <v-btn
                    class="mx-2 text-none custom-button rounded-input"
                    type="submit"
                    color="primary"
                  >
                    <template #default>
                      {{ $t('contact.send') }}
                    </template>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
  <nav-footer/>
</template>

<script>

import NavFooter from '@/components/footer/NavFooter.vue';
import CustomAppBar from '@/components/header/CustomAppBar.vue';


export default {
  name: 'Contacts',
  components: {CustomAppBar, NavFooter},
  data() {
    return {
      name: '',
      email: '',
      message: '',
      phone: '',
      imgLeft: [
        require('@/assets/pic/contacts/308009115_5324954264289266_3527439385449731812_n.png'),
        require('@/assets/pic/contacts/botox cherry-insta.jpg')
      ],
      imgRight: [
        require('@/assets/pic/contacts/1080_1080 pxl_2.jpg'),
        require('@/assets/pic/contacts/2_1cocochoco_HAIR_BOTOX_500ml_2.jpg')
      ],
      icons: [
        {
          id: 0,
          icon: 'mdi-facebook',
          color: '#1877F2',
          href: 'https://www.facebook.com/profile.php?id=100093953405775'
        },
        {
          id: 1,
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/cocochocobulgaria/?igshid=ZDc4ODBmNjlmNQ%3D%3D'
        }
      ],
    };
  },
  methods: {
    getButtonClass(color) {
      return `mx-10 ${color}`;
    },
    submitForm() {
      if (!this.name || !this.email || !this.phone || !this.message) {
        alert(this.$t('contact.alertBlanc'));
        return;
      }

      const data = {
        [this.$t('contact.name')]: this.name,
        [this.$t('contact.email')]: this.email,
        [this.$t('contact.phone')]: this.phone,
        [this.$t('contact.message')]: this.message
      };

      // Изпращане на данните на посочения имейл адрес (тук използвам просто alert за демонстрация)
      // alert(`Данни за изпращане:\n\n${JSON.stringify(data, null, 2)}`);
      alert(this.$t('contact.alertSuccess', {data: `\n\n${JSON.stringify(data, null, 2)}`}));

      // Изчистване на полетата след успешно изпращане
      this.name = '';
      this.email = '';
      this.phone = '';
      this.message = '';
    }
  }
}
</script>

<style scoped>
.contact-form {
  background-image: url('/src/assets/pic/contacts/botox splash1.jpg');
  background-size: cover;
  background-position: center;
  /*filter: brightness(0.9);*/
}

.contact-message {
  /*padding-right: 50px;*/
  text-align: center;
  padding-top: 10px;
  padding-bottom: 30px;
  /*margin-bottom: 20px;*/
  /*font: var(--fnt);*/
}

.no-bottom-border {
  border-bottom: none !important;
}

.bold-label {
  font-weight: bold;
  font-size: 30px;
  font-family: 'Roboto';
  /*font: var(--fnt);*/
}

.rounded-input {
  border-radius: 1000px;
}

.image-container {
  width: 95%;
}

.form-inputs {
  /*display: flex;*/
  flex-direction: column;
  /*align-items: center;*/
  /*height: 100%;*/
  /*justify-content: center;*/
}

</style>
