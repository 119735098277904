<template>
  <custom-app-bar/>
  <div class="about mb-auto">
    <div class="align-center">
      <v-img
        :src="require('@/assets/pic/about/about_top.png')"
        contain
      />
    </div>
    <v-container>
      <div class="d-flex flex-row mb-2 bg-surface">
        <div class="d-flex flex-column mb-10 bg-surface text-justify ma-2 pa-0">
          <h3 class="text-center ma-2 pa-2">{{ $t('about.one') }}</h3>
          <p class="text-justify ma-2 pa-2">{{ $t('about.two') }}</p>
          <h3 class="text-center ma-2 pa-2">{{ $t('about.three') }}</h3>
          <p class="text-justify ma-2 pa-2">{{ $t('about.four') }}</p>
        </div>
        <div class="ma-2 pt-2">
          <v-avatar :size="getMobileSize">
            <v-img
              max-width="450"
              max-height="450"
              :src="require('@/assets/pic/about/img_1.png')"
              contain
            />
          </v-avatar>
        </div>
      </div>

      <div class="d-flex flex-row mb-2 bg-surface">
        <div class="ma-2 pt-2">
          <v-avatar :size="getMobileSize">
            <v-img
              max-width="450"
              max-height="450"
              :src="require('@/assets/pic/about/img_2.png')"
              contain
            />
          </v-avatar>
        </div>
        <div class="d-flex flex-column mb-8 bg-surface text-justify ma-2 pa-0">
          <h3 class="text-center ma-2 pa-2">{{ $t('about.five') }}</h3>
          <p class="text-justify ma-2 pa-2">{{ $t('about.six') }}</p>
          <h3 class="text-center ma-2 pa-2">{{ $t('about.seven') }}</h3>
          <p class="text-justify ma-2 pa-2">{{ $t('about.eight') }}</p>
        </div>
      </div>
      <div class="d-flex flex-row mb-2 bg-surface">
        <div class="d-flex flex-column mb-10 bg-surface text-justify ma-2 pa-0">
          <h3 class="text-center ma-2 pa-2">{{ $t('about.nine') }}</h3>
          <p class="text-justify ma-2 pa-2">{{ $t('about.ten') }}</p>
        </div>
        <div class="ma-2 pt-2">
          <v-avatar :size="getMobileSize">
            <v-img
              max-width="450"
              max-height="450"
              :src="require('@/assets/pic/about/img_3.png')"
              contain
            />
          </v-avatar>
        </div>
      </div>
    </v-container>
  </div>
  <nav-footer/>
  <div class="scroll-to-top-btn" @click="scrollToTop">
    <v-icon>mdi-chevron-up</v-icon>
  </div>
</template>
<script>

import NavFooter from '@/components/footer/NavFooter.vue';
import CustomAppBar from '@/components/header/CustomAppBar.vue';

export default {
  name: 'About',
  components: {CustomAppBar, NavFooter},
  methods: {
    scrollToTop() {
      // Използвайте smooth scroll за плавно връщане най-отгоре на страницата
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
  },
  computed: {
    getMobileSize() {
      if (window.innerWidth <= 600) {
        return 150;
      } else {
        return 450;
      }
    }
  },

}
</script>

<style scoped>

.text-about {
  margin-left: 7px;
  margin-right: 7px;
}

/*.about {*/
/*  margin-bottom: 0;*/
/*  padding-bottom: 0;*/
/*}*/
</style>
