// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import 'vuetify/dist/vuetify.min.css'

// Vuetify
import { createVuetify } from 'vuetify'

const vuetify = createVuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: '#f35099',
        secondary: '#1d16ef',
        third: '#40B4E5',
        accent: '#8c9eff',
        error: '#b71c1c',
        background: '#d7d9d8'
      }
    }
  }
})

export default vuetify
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
