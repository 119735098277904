<template>
  <custom-app-bar/>

  <h1 class="text-center title-product" >{{$t('therapies.therapiesTitle')}}</h1>
  <div class="container">
    <div class="rows">
      <div class="product-container">
        <div v-for="(product,index) in getTherapyProducts" :key="product.id" class="product-card">
          <v-card :outlined="false" max-width="374" class="text-center mx-auto my-12" @click="scrollToDescription(index)">
            <v-img :src="product.image" height="200px"></v-img>
            <v-card-title class="v-card-title">{{ product.name }}</v-card-title>
            <v-card-title class="v-card-sub-title">{{ product.subTitle }}</v-card-title>
            <v-card-title class="v-card-sub-title-volume">{{ product.volume }}</v-card-title>
          </v-card>
        </div>
      </div>
    </div>


    <div class="descriptions ma-lg-16">
      <div class="description about-text text-justify" id="description-0">
        <div class="text-center pa-5">
          <h2>{{ $t('therapies.therapyOrigin.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('therapies.therapyOrigin.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('therapies.therapyOrigin.three') }}</p>
            <p>{{ $t('therapies.therapyOrigin.four') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('therapies.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('therapies.therapyOrigin.five') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('therapies.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('therapies.therapyOrigin.six') }}</p>
          </div>
        </div>
      </div>

      <div class="description about-text text-justify" id="description-1">
        <div class="text-center pa-5">
          <h2>{{ $t('therapies.therapyPure.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('therapies.therapyPure.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('therapies.therapyPure.three') }}</p>
            <p>{{ $t('therapies.therapyPure.four') }}</p>
            <p>{{ $t('therapies.therapyPure.five') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('therapies.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('therapies.therapyPure.six') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('therapies.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('therapies.therapyPure.seven') }}</p>
          </div>
        </div>
      </div>

      <div class="description about-text text-justify" id="description-2">
        <div class="text-center pa-5">
          <h2>{{ $t('therapies.therapyGold.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('therapies.therapyGold.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('therapies.therapyGold.three') }}</p>
            <p>{{ $t('therapies.therapyGold.four') }}</p>
            <p>{{ $t('therapies.therapyGold.five') }}</p>
            <p>{{ $t('therapies.therapyGold.six') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('therapies.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('therapies.therapyGold.seven') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('therapies.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('therapies.therapyGold.eight') }}</p>
          </div>
        </div>
      </div>

     <div class="description about-text text-justify" id="description-3">
        <div class="text-center pa-5">
          <h2>{{ $t('therapies.therapyBotox.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('therapies.therapyBotox.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('therapies.therapyBotox.three') }}</p>
            <p>{{ $t('therapies.therapyBotox.four') }}</p>
            <p>{{ $t('therapies.therapyBotox.five') }}</p>
            <p>{{ $t('therapies.therapyBotox.six') }}</p>
            <p>{{ $t('therapies.therapyBotox.seven') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('therapies.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('therapies.therapyBotox.eight') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('therapies.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('therapies.therapyBotox.nine') }}</p>
          </div>
        </div>
      </div>
      <div class="description about-text text-justify" id="description-4">
        <div class="text-center pa-5">
          <h2>{{ $t('therapies.therapyShampoo.one') }}</h2>
        </div>
        <div class="text-justify pa-10">
          <p>{{ $t('therapies.therapyShampoo.two') }}</p>
          <div class="text-justify ml-10 pt-3">
            <p>{{ $t('therapies.therapyShampoo.three') }}</p>
            <p>{{ $t('therapies.therapyShampoo.four') }}</p>
            <p>{{ $t('therapies.therapyShampoo.five') }}</p>
            <p>{{ $t('therapies.therapyShampoo.six') }}</p>
            <p>{{ $t('therapies.therapyShampoo.seven') }}</p>
            <p>{{ $t('therapies.therapyShampoo.eight') }}</p>
              <p>{{ $t('therapies.therapyShampoo.nine') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('therapies.composition') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('therapies.therapyShampoo.ten') }}</p>
          </div>
          <div class="text-center pa-5">
            <h3>{{ $t('therapies.instructions') }}</h3>
          </div>
          <div class="text-justify">
            <p>{{ $t('therapies.therapyShampoo.eleven') }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="description about-text text-justify" id="description-5">
      <div class="text-center pa-5">
        <h2>{{ $t('therapies.Silk365Green.one') }}</h2>
      </div>
      <div class="text-justify pa-10">
        <p>{{ $t('therapies.Silk365Green.two') }}</p>
        <div class="text-justify ml-10 pt-3">
          <p>{{ $t('therapies.Silk365Green.three') }}</p>
          <p>{{ $t('therapies.Silk365Green.four') }}</p>
          <p>{{ $t('therapies.Silk365Green.five') }}</p>
          <p>{{ $t('therapies.Silk365Green.six') }}</p>
        </div>
        <div class="text-center pa-5">
          <h3>{{ $t('therapies.composition') }}</h3>
        </div>
        <div class="text-justify">
          <p>{{ $t('therapies.Silk365Green.seven') }}</p>
        </div>
        <div class="text-center pa-5">
          <h3>{{ $t('therapies.instructions') }}</h3>
        </div>
        <div class="text-justify">
          <p>{{ $t('therapies.Silk365Green.eight') }}</p>
        </div>
      </div>
    </div>

    <div class="description about-text text-justify" id="description-6">
      <div class="text-center pa-5">
        <h2>{{ $t('therapies.Silk365Red.one') }}</h2>
      </div>
      <div class="text-justify pa-10">
        <p>{{ $t('therapies.Silk365Red.two') }}</p>
        <div class="text-justify ml-10 pt-3">
          <p>{{ $t('therapies.Silk365Red.three') }}</p>
          <p>{{ $t('therapies.Silk365Red.four') }}</p>
          <p>{{ $t('therapies.Silk365Red.five') }}</p>
          <p>{{ $t('therapies.Silk365Red.six') }}</p>
        </div>
        <div class="text-center pa-5">
          <h3>{{ $t('therapies.composition') }}</h3>
        </div>
        <div class="text-justify">
          <p>{{ $t('therapies.Silk365Red.seven') }}</p>
        </div>
        <div class="text-center pa-5">
          <h3>{{ $t('therapies.instructions') }}</h3>
        </div>
        <div class="text-justify">
          <p>{{ $t('therapies.Silk365Red.eight') }}</p>
        </div>
      </div>
    </div>

    <div class="description about-text text-justify" id="description-7">
      <div class="text-center pa-5">
        <h2>{{ $t('therapies.Silk365Yellow.one') }}</h2>
      </div>
      <div class="text-justify pa-10">
        <p>{{ $t('therapies.Silk365Yellow.two') }}</p>
        <div class="text-justify ml-10 pt-3">
          <p>{{ $t('therapies.Silk365Yellow.three') }}</p>
          <p>{{ $t('therapies.Silk365Yellow.four') }}</p>
          <p>{{ $t('therapies.Silk365Yellow.five') }}</p>
          <p>{{ $t('therapies.Silk365Yellow.six') }}</p>
          <p>{{ $t('therapies.Silk365Yellow.seven') }}</p>
        </div>
        <div class="text-center pa-5">
          <h3>{{ $t('therapies.composition') }}</h3>
        </div>
        <div class="text-justify">
          <p>{{ $t('therapies.Silk365Yellow.eight') }}</p>
        </div>
        <div class="text-center pa-5">
          <h3>{{ $t('therapies.instructions') }}</h3>
        </div>
        <div class="text-justify">
          <p>{{ $t('therapies.Silk365Yellow.nine') }}</p>
        </div>
      </div>
    </div>
    <div class="description about-text text-justify" id="description-8">
      <div class="text-center pa-5">
        <h2>{{ $t('therapies.Silk365Blue.one') }}</h2>
      </div>
      <div class="text-justify pa-10">
        <p>{{ $t('therapies.Silk365Blue.two') }}</p>
        <div class="text-justify ml-10 pt-3">
          <p>{{ $t('therapies.Silk365Blue.three') }}</p>
          <p>{{ $t('therapies.Silk365Blue.four') }}</p>
          <p>{{ $t('therapies.Silk365Blue.five') }}</p>
          <p>{{ $t('therapies.Silk365Blue.six') }}</p>
        </div>
        <div class="text-center pa-5">
          <h3>{{ $t('therapies.composition') }}</h3>
        </div>
        <div class="text-justify">
          <p>{{ $t('therapies.Silk365Blue.seven') }}</p>
        </div>
        <div class="text-center pa-5">
          <h3>{{ $t('therapies.instructions') }}</h3>
        </div>
        <div class="text-justify">
          <p>{{ $t('therapies.Silk365Blue.eight') }}</p>
        </div>
      </div>
    </div>
  </div>
  <nav-footer/>
  <div class="scroll-to-top-btn" @click="scrollToTop">
    <v-icon>mdi-chevron-up</v-icon>
  </div>
</template>

<script>
import NavFooter from '@/components/footer/NavFooter.vue';
import CustomAppBar from '@/components/header/CustomAppBar.vue';

export default {
  name: 'Therapies',
  components: {CustomAppBar, NavFooter},
  data() {
    return {
    }
  },
  computed: {
    getTherapyProducts() {
      return [
        {
          id: 0,
          name: 'Original',
          subTitle: this.$t('therapies.therapyOrigin.price'),
          volume: '1000 ml.',
          image: require('@/assets/pic/therapies/BKO1000_1_2023.jpg'),

        },
        {
          id: 1,
          name: 'Pure',
          subTitle: this.$t('therapies.therapyPure.price'),
          volume: '1000 ml.',
          image: require('@/assets/pic/therapies/BKP1000_1_2023.jpg'),

        },
        {
          id: 2,
          name: '24K Gold',
          subTitle: this.$t('therapies.therapyGold.price'),
          volume: '1000 ml.',
          image: require('@/assets/pic/therapies/BKG1000_1_2023.jpg'),

        },
        {
          id: 3,
          name: 'Hair Botox',
          subTitle: this.$t('therapies.therapyBotox.price'),
          volume: '500 ml.',
          image: require('@/assets/pic/therapies/vb500.jpg'),

        },
        {
          id: 4,
          name: 'Салонен  шампоан',
          subTitle: this.$t('therapies.therapyShampoo.price'),
          volume: '1000 ml.',
          image: require('@/assets/pic/therapies/cs1000.jpg'),
        },
        {
          id: 5,
          name: 'Silk 365 Expert Line',
          subTitle: this.$t('therapies.Silk365Green.price'),
          volume: '1000 ml.',
          image: require('@/assets/pic/therapies/1134_scs1000.jpg'),
        },
        {
          id: 6,
          name: 'Silk 365 Expert Line',
          subTitle: this.$t('therapies.Silk365Red.price'),
          volume: '1000 ml.',
          image: require('@/assets/pic/therapies/1137_scss1000.jpg'),
        },
        {
          id: 7,
          name: 'Silk 365 Expert Line',
          subTitle: this.$t('therapies.Silk365Yellow.price'),
          volume: '1000 ml.',
          image: require('@/assets/pic/therapies/1140_sobs1000.jpg'),
        },
        {
          id: 8,
          name: 'Silk 365 Expert Line',
          subTitle: this.$t('therapies.Silk365Blue.price'),
          volume: '1000 ml.',
          image: require('@/assets/pic/therapies/1143_scso1000.jpg'),
        }
      ]
    }
  },
  methods: {
    scrollToDescription(index) {
      // Изчакайте малко, за да осигурите изпълнението на Vue.js и DOM обновленията
      this.$nextTick(() => {
        const descriptionElement = document.getElementById(`description-${index}`);
        if (descriptionElement) {
          // Използвайте smooth scroll за плавно препращане към описанието
          descriptionElement.scrollIntoView({behavior: 'smooth'});
        }
      });
    },
    scrollToTop() {
      // Използвайте smooth scroll за плавно връщане най-отгоре на страницата
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
  },
}
</script>

<style scoped>
.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product-card {
  margin: 30px;
  width: 200px;
}

.product-card-inner {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 49px;
  font-weight: 10;
  font-family: 'Roboto';
}

.product-card-inner v-img {
  /* Задайте фиксирана височина на изображенията, за да се адаптират към височината на картичката */
  height: 200px;
  width: auto;
}

.v-card-title {
  /* Задайте ограничена ширина на тайтъла и скрийте излишния текст със свойството text-overflow */
  max-width: 200px; /* Тук задайте желаната дължина на тайтъла */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-card-sub-title {
  /* Задайте ограничена ширина на тайтъла и скрийте излишния текст със свойството text-overflow */
  max-width: 200px; /* Тук задайте желаната дължина на тайтъла */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
}

.v-card-sub-title-volume {
  /* Задайте ограничена ширина на тайтъла и скрийте излишния текст със свойството text-overflow */
  max-width: 200px; /* Тук задайте желаната дължина на тайтъла */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
}

.container {
  font-size: 18px;
}

.rows {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.row {
  cursor: pointer;
  margin-bottom: 10px;
  color: blue;
  text-decoration: underline;
}

.description h2::after {
  content: ''; /* Добавете съдържание на псевдоелемента */
  position: absolute; /* Задайте позиция absolute, за да го позиционирате под заглавието */
  bottom: -7px; /* Задайте разстояние под заглавието */
  left: 50%; /* Позицията на псевдоелемента от ляво до дясно */
  width: 100%; /* Ширина на псевдоелемента, която да покрива цялата ширина на заглавието */
  height: 7px; /* Височина на линията, може да се промени според вашия дизайн */
  background-color: darkgoldenrod; /* Цветът на линията */
  transform: translateX(-50%); /* Центриране на псевдоелемента спрямо собствения му център */
}

</style>
