export const headFooterMixins = {
  data() {
    return {
      icons: [
        {
          id: 0,
          icon: 'mdi-facebook',
          color: '#1877F2',
          href: 'https://www.facebook.com/profile.php?id=100093953405775'
        },
        {
          id: 1,
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/cocochocobulgaria/?igshid=ZDc4ODBmNjlmNQ%3D%3D'
        }
      ],
    }
  },
  computed: {
    getLink() {
      return [
        // {link: this.$t('mainBtn.home'), id: 'home'},
        {link: this.$t('mainBtn.therapies'), id: 'therapies'},
        {link: this.$t('mainBtn.afterCare'), id: 'afterCare'},
        {link: this.$t('mainBtn.about'), id: 'about'},
        {link: this.$t('mainBtn.salons'), id: 'salons'},
        {link: this.$t('mainBtn.contacts'), id: 'contacts'},
      ];
    },
  },
  methods: {
    redirectToAnotherComponent(button) {
      if (button === 'home') {
        this.$router.push('/');
      }
      if (button === 'therapies') {
        this.$router.push('/therapies');
      }
      if (button === 'afterCare') {
        this.$router.push('/after-care');
      }
      if (button === 'about') {
        this.$router.push('/about');
      }
      if (button === 'salons') {
        this.$router.push('/salons');
      }
      if (button === 'contacts') {
        this.$router.push('/contacts');
      }
      window.scrollTo({top: 0, behavior: 'smooth'});

      return {};
    },
    getButtonClass(color) {
      return `mx-4 ${color} my-button`;
    },
  }
};
