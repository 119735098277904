<template>
  <custom-app-bar/>
  <h1 class="salons-city text-center title-product">{{ $t('salons.partnerTitle') }}</h1>

  <div class="d-flex justify-center flex-row mb-2 word-buttons">
    <div class="ma-2 pa-2 word-button"
      v-for="(city,index) in fetchSalonsFromCities"
         :key="city">
    <div class="ma-2 pa-2 " @click="scrollToDescription(index)">{{city}}</div>
    </div>
    </div>

  <div class="align-center">
    <v-img
      :height="600"
      :src="require('@/assets/pic/salons/about.png')"
      contain
    />
  </div>

  <div class="contact-message pb-5"
       v-for="(city,index) in fetchSalonsFromCities"
       :key="city">
    <div class="descriptions ma-lg-16">
      <div class="description salons-city text-center" :id="'description-' + index">
        <h2>{{ city }}:</h2>
      </div>
    </div>
    <v-container
      :fluid="true"
      class="user-card-container"
    >
      <v-card
        elevation="5"
        class="user-card"
        v-for="salon in getCities(city)"
        :key="salon.id"
      >
        <v-img
          class="ml-3 mt-3"
          max-height="35"
          max-width="45"
          :src="salon.src"/>
        <v-card-title class="text-center">{{ salon.name }}</v-card-title>
        <v-card-subtitle class="text-center">
          <template v-if="salon.hrefSite">
            <a :href="salon.hrefSite" target="_blank">{{ salon.hrefSite }}</a>
          </template>
          <template v-else>
            {{ salon.name }}
          </template>
        </v-card-subtitle>
        <v-card-actions class="justify-center">
          <p class="ml-auto text-follow">{{ $t('followUs') }}</p>
          <v-btn
            :class="getButtonClass(salon.color)"
            :color="salon.color"
            :icon="salon.icon"
            variant="text"
            target="_blank"
            :href="salon.href"
          />
          <v-btn
            v-if="salon?.href2"
            :class="getButtonClass(salon.color)"
            :color="salon.color2"
            :icon="salon.icon2"
            variant="text"
            target="_blank"
            :href="salon.href2"
          />
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
  <nav-footer/>
  <div class="scroll-to-top-btn" @click="scrollToTop">
    <v-icon>mdi-chevron-up</v-icon>
  </div>
</template>

<script>
import NavFooter from '@/components/footer/NavFooter.vue';
import CustomAppBar from '@/components/header/CustomAppBar.vue';

export default {
  name: 'Salons',
  components: {CustomAppBar, NavFooter},
  data() {
    return {
      salonsSofia: [
        {
          id: 0,
          name: 'MV Милен Василев',
          src: require('@/assets/pic/salons/MV salon.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          hrefSite: 'https://www.mvsalon.bg/bg/about/',
          href: 'https://www.instagram.com/mv.salon/'
        },
        {
          id: 1,
          name: 'EV House of Beauty Ева Вучкова',
          src: require('@/assets/pic/salons/ev_house_of_beauty.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/ev_house_of_beauty/'
        },
        {
          id: 2,
          name: 'Pose by Boyan Vasilev',
          src: require('@/assets/pic/salons/pose.by.boyanvasilev.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/pose.by.boyanvasilev/'
        },
        {
          id: 3,
          name: 'Hashtag Beauty Studio Георги Цветанов',
          src: require('@/assets/pic/salons/joro_by_hashtag_beauty_studio.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/joro_by_hashtag_beauty_studio/'
        },
        {
          id: 4,
          name: 'Салон Интрига Иван Янков',
          src: require('@/assets/pic/salons/intriggue.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/intriggue/'
        },
        {
          id: 5,
          name: 'Petya Totkova by Stylist Kapanov',
          src: require('@/assets/pic/salons/totkovapetia.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/totkovapetia/'
        },
        {
          id: 6,
          name: 'CARRÉ hair studio Екатерина Караиванова',
          src: require('@/assets/pic/salons/hair_studio_carre.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/hair_studio_carre/'
        },
        {
          id: 7,
          name: 'Polly Style Salon  Поли Гемеджиева',
          src: require('@/assets/pic/salons/polly_style_salon.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/polly_style_salon/'
        },
        {
          id: 8,
          name: 'NOA Beauty Lounge',
          src: require('@/assets/pic/salons/noa_beauty_lounge.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/noa_beauty_lounge/'
        },
        {
          id: 9,
          name: 'Arlet Stars Ивета Атанасова',
          src: require('@/assets/pic/salons/arletstars.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/arletstars/'
        },
        {
          id: 10,
          name: 'Cveti’s Secret',
          src: require('@/assets/pic/salons/cvetissecret.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/cvetissecret/'
        },
        {
          id: 11,
          name: 'Bo-Ny Brothers Salon Николай Филаретов',
          src: require('@/assets/pic/salons/bony_brothers_salon.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/bony_brothers_salon/'
        },
        {
          id: 12,
          name: 'Salon Nushi Конда Кондов',
          src: require('@/assets/pic/salons/kondakondov.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/kondakondov/'
        },
        {
          id: 13,
          name: 'Салон Макрати –Люлин',
          src: require('@/assets/pic/salons/makratielena.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/makratielena/'
        },
        {
          id: 14,
          name: 'HAIR CLUB by Dilyana Piskova',
          src: require('@/assets/pic/salons/dilyanapiskovahairstylist.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/dilyanapiskovahairstylist/'
        },
        {
          id: 15,
          name: 'Artistic studio Драгомир Николаев',
          src: require('@/assets/pic/salons/artistic_studio33.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/artistic_studio33/'
        },
        {
          id: 16,
          name: 'Etude hair studio Десислава Миланова',
          src: require('@/assets/pic/salons/etude_hair_studio.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/etude_hair_studio/'
        },
        {
          id: 17,
          name: 'Salon Amor Мирослава Гачева',
          src: require('@/assets/pic/salons/salon_amor2022.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/salon_amor2022/'
        },
        {
          id: 18,
          name: 'Salon Stela Виолета Цветкова',
          src: require('@/assets/pic/salons/stela_beauty_group.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/stela_beauty_group/'
        },
        {
          id: 19,
          name: 'SALON Natalia Milanova Гюнай',
          src: require('@/assets/pic/salons/natalia.milanova.salon.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/natalia.milanova.salon/'
        },
        {
          id: 20,
          name: 'Daisy Beauty Studio Антония Веселинова',
          src: require('@/assets/pic/salons/daisybeautystudio.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/daisybeautystudio/'
        },
        {
          id: 21,
          name: 'Петя Цветкова',
          src: require('@/assets/pic/salons/pfashion353.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/pfashion353/'
        },
        {
          id: 22,
          name: 'SalonNatali',
          src: require('@/assets/pic/salons/salonnatali7.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/salonnatali7/'
        },
        {
          id: 23,
          name: 'Lady\'s Corner',
          src: require('@/assets/pic/salons/ladys__corner.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/ladys__corner/'
        },
        {
          id: 24,
          name: 'Hair style by Tsvety Tsaneva',
          src: require('@/assets/pic/salons/hair_style_by_tsvety_tsaneva.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/hair_style_by_tsvety_tsaneva/'
        },
        {
          id: 25,
          name: 'AngelGanev|CreativeHair',
          src: require('@/assets/pic/salons/angelganevhair.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/angelganevhair/'
        },
        {
          id: 26,
          name: 'Салон за красота Mood Factory',
          src: require('@/assets/pic/salons/moodfactorysofia.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/moodfactorysofia/'
        },
        {
          id: 27,
          name: 'AnMars Luxury Studio',
          src: require('@/assets/pic/salons/anmarsluxury.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/anmarsluxury/'
        },
        {
          id: 28,
          name: '109 Прически',
          src: require('@/assets/pic/salons/109pricheski.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/109pricheski/'
        },
        {
          id: 29,
          name: 'Remedy beauty room',
          src: require('@/assets/pic/salons/gospojicanojicaa.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/gospojicanojicaa/'
        },
        {
          id: 30,
          name: 'Салон Еуфория',
          src: require('@/assets/pic/salons/tanqjelezarova.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/tanqjelezarova/'
        },
        {
          id: 31,
          name: 'Поли Стоянова',
          src: require('@/assets/pic/salons/beauty_centerlv.jpg'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/beauty_centerlv/'
        },
        {
          id: 32,
          name: 'Ани Иванова',
          src: require('@/assets/pic/salons/annabellebeautyhouse.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/annabellebeautyhouse/'
        },
        {
          id: 33,
          name: 'AJ Beauty Studio',
          src: require('@/assets/pic/salons/aj.beautystudio_2017.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/aj.beautystudio_2017/'
        },
        {
          id: 34,
          name: 'Studio Vergy',
          src: require('@/assets/pic/salons/studio_vergy.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/vergy_studio/'
        },
        {
          id: 35,
          name: 'KARAMEL Beauty Bar',
          src: require('@/assets/pic/salons/KARAMEL Beauty Bar.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/karamel.beauty.bar/'
        },
        {
          id: 36,
          name: 'Royal Beauty Room ',
          src: require('@/assets/pic/salons/Royal Beauty Room.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/royal.beautyroom/'
        },
        {
          id: 37,
          name: 'Beauty Bar by Ognyan Kirilov',
          src: require('@/assets/pic/salons/BeautyBar by Ognyan Kirilov.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/beautybar.by.ognyan.kirilov/'
        },
        {
          id: 38,
          name: 'Simeon Hair Style',
          src: require('@/assets/pic/salons/SimeonHairStyle.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/simeonhairstyle/'
        },
        {
          id: 39,
          name: 'Салон Кристиан Кирилов',
          src: require('@/assets/pic/salons/kristian-hair-salon.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/kristian.hair.salon/'
        },
        {
          id: 40,
          name: 'Салон Рефреш',
          src: require('@/assets/pic/salons/salon_refresh.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/salon_refresh_/'
        },
        {
          id: 41,
          name: 'Brillare by BM',
          src: require('@/assets/pic/salons/brillare_by_bm.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/brillare_by_bm/'
        },
        {
          id: 42,
          name: 'Anita Beauty Salon',
          src: require('@/assets/pic/salons/anitabeautysalon1.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/anitabeautysalon1/'
        },
        {
          id: 43,
          name: 'FT Future trend',
          src: require('@/assets/pic/salons/ftfuturetrend.png'),
          icon: 'mdi-instagram',
          icon2: 'mdi-facebook',
          color: '#C13584',
          color2: '#1877F2',
          href: 'https://www.instagram.com/ftfuturetrend/',
          href2: 'https://www.facebook.com/salonzakrasotaft'
        },
      ],
      salonsBlagoevgrad: [
        {
          id: 0,
          name: 'Beauty Salon G&D',
          src: require('@/assets/pic/salons/gdbeautysalon.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/gdbeautysalon/'
        },
        {
          id: 1,
          name: 'Студио за красота "Ками"',
          src: require('@/assets/pic/salons/Студио за красота Ками.png'),
          icon: 'mdi-facebook',
          color: '#1877F2',
          href: 'https://www.facebook.com/profile.php?id=100051590720701'
        },
      ],
      salonsBurgas: [
        {
          id: 0,
          name: '„Салонът“ Кремена Колева',
          src: require('@/assets/pic/salons/kremenakoleva.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/kremenakoleva/'
        },
        {
          id: 1,
          name: 'Фризьорница',
          src: require('@/assets/pic/salons/beauty_bar_and_shop.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/beauty_bar_and_shop/'
        },
      ],
      salonsVarna: [
        {
          id: 0,
          name: 'Atelier Kina Kudeva',
          src: require('@/assets/pic/salons/kinakudeva.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/kinakudeva/'
        },
        {
          id: 1,
          name: 'BARBER CODE',
          src: require('@/assets/pic/salons/BARBER CODE.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          hrefSite: 'https://barbercode.bg/',
          href: 'https://www.instagram.com/barber.code.varna/'
        },
        {
          id: 2,
          name: 'Studio One',
          src: require('@/assets/pic/salons/STUDIO ONE.png'),
          icon: 'mdi-facebook',
          color: '#1877F2',
          href: 'https://www.facebook.com/1studioone'
        },
        {
          id: 3,
          name: 'FRINGE - Hair&Beauty',
          src: require('@/assets/pic/salons/FRINGE- Hair&Beauty.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/fringevarna/'
        },
        {
          id: 4,
          name: 'Студио Адикт',
          src: require('@/assets/pic/salons/studio_addict.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/studio_addict/'
        },
        {
          id: 5,
          name: 'VISTYLE',
          src: require('@/assets/pic/salons/beauty_of_vistyle.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/beauty_of_vistyle/'
        },
      ],
      salonsPernik: [
        {
          id: 0,
          name: 'Beauty Studio "DIAMOND"',
          src: require('@/assets/pic/salons/Beauty Studio Diamond.png'),
          icon: 'mdi-facebook',
          color: '#1877F2',
          href: 'https://www.facebook.com/profile.php?id=100066542387642'
        },
      ],
      salonsPlovdiv: [
        {
          id: 0,
          name: 'Надежда Грозданова',
          src: require('@/assets/pic/salons/chloes_studio_za_krasota.png'),
          icon: 'mdi-instagram',
          color: '#C13584',
          href: 'https://www.instagram.com/chloes_studio_za_krasota/'
        },
      ],
      salonsHarmanli: [
        {
          id: 0,
          name: 'Салон “D Beauty Secrets"',
          src: require('@/assets/pic/salons/D_Beauty_Secrets.png'),
          icon: 'mdi-instagram',
          icon2: 'mdi-facebook',
          color: '#C13584',
          color2: '#1877F2',
          href: 'https://www.instagram.com/d.beauty.secrets/',
          href2: 'https://www.facebook.com/profile.php?id=100050764133689'
        },
      ]
    }
  },
  computed: {
    fetchSalonsFromCities() {
      return [
        this.$t('salons.blagoevgrad'),
        this.$t('salons.burgas'),
        this.$t('salons.varna'),
        this.$t('salons.pernik'),
        this.$t('salons.plovdiv'),
        this.$t('salons.sofia'),
        this.$t('salons.harmanli'),
      ]
    },
  },
  methods: {
    getButtonClass(color) {
      return `mx-1 ${color}`;
    },
    getCities(city) {
      if (city === this.$t('salons.sofia')) {
        return this.salonsSofia;
      }
      if (city === this.$t('salons.blagoevgrad')) {
        return this.salonsBlagoevgrad;
      }
      if (city === this.$t('salons.burgas')) {
        return this.salonsBurgas;
      }
      if (city === this.$t('salons.varna')) {
        return this.salonsVarna;
      }
      if (city === this.$t('salons.plovdiv')) {
        return this.salonsPlovdiv;
      }
      if (city === this.$t('salons.pernik')) {
        return this.salonsPernik;
      }
      if (city === this.$t('salons.harmanli')) {
        return this.salonsHarmanli;
      }
    },
    scrollToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    scrollToDescription(index) {
      this.$nextTick(() => {
        const descriptionElement = document.getElementById(`description-${index}`);
        if (descriptionElement) {
          descriptionElement.scrollIntoView({behavior: 'smooth'});
        }
      });
    },
  },
}
</script>

<style scoped>

.user-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.user-card {
  background-color: #e0e0e0;
  flex: 0 0 calc(20% - 20px);
  max-width: calc(20% - 20px);
  margin: 10px;
}

@media (max-width: 600px) {
  .user-card {
    flex-basis: calc(100% - 20px);
    max-width: calc(100% - 20px);
  }

  .word-buttons {
    flex-wrap: wrap; /* Позволява думите да се пренареждат на нов ред при малка ширина */
    justify-content: center; /* Центрира думите по хоризонтала в мобилния изглед */
  }

  .word-button {
    margin-bottom: 1px; /* Отстояние между думите в мобилния изглед */
    margin-left: 30px; /* Намаляваме отстоянието отляво за мобилен изглед */
    margin-right: 30px; /* Намаляваме отстоянието отдясно за мобилен изглед */
  }
}

.salons-city {
  font-family: 'Roboto';
  font-size: 30px;
  font-weight: 400;
  margin: 30px;
}

.word-buttons {
  display: flex;
  justify-content: space-between;
  /*padding: 0 200px;*/
  font-weight: 400;
  font-family: Roboto, serif;
  font-size: 25px;
  color: darkgoldenrod;
}

.word-button {
  /*padding: 8px 16px;*/
  cursor: pointer;
  /*margin-left: 30px; !* Отстояние отляво *!*/
  /*margin-right: 30px; !* Отстояние отдясно *!*/
}

.background-salons {
  /*background-image: url('/src/assets/pic/contacts/botox splash1.jpg');*/
}

.description h2::after {
  content: '.....'; /* Добавете съдържание на псевдоелемента */
  position: absolute; /* Задайте позиция absolute, за да го позиционирате под заглавието */
  bottom: -52px; /* Задайте разстояние под заглавието */
  left: 50%; /* Променете позицията на псевдоелемента, за да е центрирано под заглавието */
  font-size: 66px; /* Размер на точките */
  line-height: 2; /* Нулиране на височината на реда за да се скрие ненужния интервал между точките */
  color: darkgoldenrod; /* Цвят на точките */
  transform: translateX(-49%); /* Центриране на псевдоелемента спрямо собствения му център */
}
</style>
