import {createRouter, createWebHistory} from 'vue-router'
import Index from '@/views/Index.vue';
import AboutView from '@/components/about/AboutView.vue';
import Contacts from '@/components/contacts/Contacts.vue';
import Salons from '@/components/salons/Salons.vue';
import Therapies from '@/components/therapies/Therapies.vue';
import AfterCare from '@/components/afterCare/AfterCare.vue';

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../components/about/AboutView.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '@/components/contacts/Contacts.vue')
  },
  {
    path: '/salons',
    name: 'salons',
    component: Salons
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '@/components/salons/Salons.vue')
  },
  {
    path: '/after-care',
    name: 'afterCare',
    component: AfterCare
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '@/components/followUpCare/AfterCare.vue')
  },
  {
    path: '/therapies',
    name: 'therapies',
    component: Therapies
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '@/components/therapies/Therapies.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  mode: 'hash',
  history: createWebHistory(),
  routes
})

export default router
